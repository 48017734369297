<template>
	<div>
		<CRow>
			<CCol class="btn-wrapper">
				<CButton
					block
					class="transparent"
					color="secondary"
					data-test-id="add-accordion-btn"
					@click="$router.push({ name: ROUTE_NAME.CREATE_PRODUCT_DETAIL_ACCORDION_SET })"
				>
					<CIcon class="icon-left" name="cil-plus" />
					Add
				</CButton>
			</CCol>
			<CCol>
				<form
					class="search-form"
					@submit.prevent="handleSearch"
				>
					<CInput
						v-model.trim="queryParams.q"
						data-test-id="accordion-sets-search"
						type="text"
						placeholder="Search"
					>
						<template #prepend-content>
							<button
								type="submit"
								class="btn-search"
							>
								<FontAwesomeIcon :icon="['far', 'search']" />
							</button>
						</template>
					</CInput>
				</form>
			</CCol>
		</CRow>
		<BaseTable
			:is-loading="list.isLoading"
			:fields="FIELDS"
			:items="accordionSets"
			:pagination="{
				pages: list.meta.lastPage,
				activePage: list.meta.currentPage
			}"
			class="table-custom"
			striped
			:link-to="ROUTE_NAME.EDIT_PRODUCT_DETAIL_ACCORDION_SET"
			@onPaginationClick="handlePageChange"
		>
			<template #no-items-view>
				<div class="empty-table-element">
					<p class="subtitle">
						{{ $t("global.searchNotFound", {field: "accordion set"}) }}
					</p>
				</div>
			</template>
			<template #deleteId="{item}">
				<CRow>
					<CCol class="text-right">
						<CButton
							class="transparent p-0"
							@click.prevent="openModalDelete(item)"
						>
							<CIcon
								class="icon-trash"
								name="cil-trash"
							/>
						</CButton>
					</CCol>
				</CRow>
			</template>
		</BaseTable>
		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteAccordionSet.bind(null, selectedSetId)"
			title="Remove this accordion"
			description="Are you sure you want to delete this accordion?"
			@onSuccess="handleDeleteSuccess"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { cleanObject, randomSearchString } from '../assets/js/helpers';
import { ROUTE_NAME } from '../enums/route';

const FIELDS = [
	{ key: 'id', label: 'ID', class: 'col-id' },
	{ key: 'name', label: 'Accordion Set', class: 'col-name' },
	{ key: 'deleteId', label: '', class: 'col-id' },
];

export default {
	name: 'ProductDetailAccordionSet',
	components: {},
	data() {
		return {
			FIELDS,
			selectedSetId: null,
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				r: randomSearchString(),
			},
		};
	},
	computed: {
		ROUTE_NAME() {
			return ROUTE_NAME;
		},
		...mapState('accordionSets', {
			list: 'list',
			create: 'create',
		}),
		...mapGetters({
			accordionSets: 'accordionSets/accordionList',
		}),
	},
	created() {
		this.getAccordionSets(this.queryParams);
	},
	methods: {
		...mapActions({
			getAccordionSets: 'accordionSets/getAccordionSets',
			createAccordionSet: 'accordionSets/createAccordionSet',
			deleteAccordionSet: 'accordionSets/deleteAccordionSet',
			resetErrorCreateAccordionSet: 'accordionSets/resetErrorCreateAccordionSet',
		}),
		updateUrlParams() {
			const query = cleanObject(JSON.parse(JSON.stringify(this.queryParams)));
			this.$router.push({ query });
		},
		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},
		async handleDeleteSuccess() {
			this.selectedSetId = null;
			this.queryParams.q = '';
			this.queryParams.page = null;
			await this.getAccordionSets(this.queryParams);
		},
		openModalDelete(accordionSetId) {
			this.selectedSetId = accordionSetId;
			this.$refs['modal-remove'].open();
		},
	},
};
</script>

<style lang="scss" scoped>
.btn-wrapper {
	max-width: rem(132);
}

.btn-search {
	background: transparent;
	box-shadow: none;
	border: none;
	padding: 0;
}

.icon-trash {
	color: $color-black-25;
}

::v-deep .col-id {
	width: 20%;
}
</style>
